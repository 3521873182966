import { isMobileOnly } from "react-device-detect";
import "./Video.scss";

const Video = ({ src, title }) => {
  return (
    <iframe
      src={src}
      className={isMobileOnly ? "mobile" : "desktop"}
      title={title}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};

export default Video;

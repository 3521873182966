import { useState, useEffect } from "react";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import InputTags from "../InputTags/InputTags";
import FormTabs from "../FormTabs/FormTabs";
import { useTranslation } from "react-i18next";

const FormTestimony = (props) => {
  const { t } = useTranslation();
  const { register, setValue, tags, setTags, type, setType, formType } = props;

  let urlinit = (window.location.href.split("id=")[1] || "")
    .split("&")[0]
    .split("#")[0];

  const [prefillid, setPrefillid] = useState(
    formType === "drafts"
      ? urlinit
      : urlinit
      ? "https://districtr.org/edit/" + urlinit
      : null
  );

  useEffect(() => {
    if (window.location.href.includes("planid=")) {
      const prefill =
        "https://districtr.org/plan/" +
        window.location.href.split("planid=")[1].split("&")[0].split("#")[0];
      setType("plan");
      setPrefillid(prefill);
      if (window.location.hash) {
        document.documentElement.scrollTop =
          document.getElementById(window.location.hash.substring(1)).offsetTop -
          40;
      }
    } else if (window.location.href.includes("coiid=")) {
      const prefill =
        "https://districtr.org/COI/" +
        window.location.href.split("coiid=")[1].split("&")[0].split("#")[0];
      setType("coi");
      setPrefillid(prefill);
      if (window.location.hash) {
        document.documentElement.scrollTop =
          document.getElementById(window.location.hash.substring(1)).offsetTop -
          40;
      }
    } else if (window.location.href.includes("commentid=")) {
      const prefill =
        "" +
        window.location.href.split("commentid=")[1].split("&")[0].split("#")[0];

      if (!isNaN(prefill.slice(1))) {
        setPrefillid(prefill);
      }
      setType("comment");
      if (window.location.hash) {
        document.documentElement.scrollTop =
          document.getElementById(window.location.hash.substring(1)).offsetTop -
          40;
      }
    } else if (window.location.href.includes("draftid=")) {
      const prefill =
        "" +
        window.location.href.split("draftid=")[1].split("&")[0].split("#")[0];
      if (!isNaN(prefill.slice(1))) {
        setPrefillid(prefill);
      }
      setType("plan");
      if (window.location.hash) {
        document.documentElement.scrollTop =
          document.getElementById(window.location.hash.substring(1)).offsetTop -
          40;
      }
    }
  }, [setType]);

  return (
    <div>
      <Form.Row>
        <Form.Group as={Col} controlId="title">
          <Form.Label>
            <strong>{t("submit.submission.title.label")}</strong>
          </Form.Label>
          <Form.Control
            required
            defaultValue=""
            disabled={type === "comment"}
            type="text"
            ref={register}
            name="title"
            className="testimony"
            placeholder={
              formType === "drafts"
                ? type === "comment"
                  ? t("drafts.form.title.comment") + prefillid
                  : t("drafts.form.title.placeholder")
                : t("submit.submission.title.placeholder")
            }
            maxLength="40"
            style={{ width: "75%" }}
          />
        </Form.Group>
      </Form.Row>
      <FormTabs
        prefillid={prefillid}
        setPrefillid={setPrefillid}
        register={register}
        setValue = {setValue}
        type={type}
        setType={setType}
        formType={formType}
        setBlockSubmit={props.setBlockSubmit}
        districtrContent={props.districtrContent}
        setDistrictrContent={props.setDistrictrContent}
      />
      {t("tags:examples", { returnObjects: true }) && (
        <div>
          <br />
          <strong>{t("inputTags.title")}</strong>
          <br /> {t("inputTags.explainer")}
          <InputTags tags={tags} setTags={setTags} controlId="formtags" />
        </div>
      )}
    </div>
  );
};

export default FormTestimony;

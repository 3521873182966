import { useState } from "react";
import { isMobileOnly } from "react-device-detect";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ALink, { JLink, Link } from "../ALink/ALink.jsx";
import Anchor from "../Anchor/Anchor.jsx";
import Video from "../Video/Video.jsx";
import GalleryCardIcon from "../GalleryItem/GalleryCardIcon.jsx";
import "./Welcome.scss";
import { useTranslation, Trans } from "react-i18next";





const Welcome = () => {
  const { t } = useTranslation();
  const [expansionType, setExpansionType] = useState("");

  const exampleCard = (type) => {
    const example = t(`examples:${type}`, { returnObjects: true });
    return (
      example &&
      example.id && (
        <Row>
          <GalleryCardIcon
            title={example.title}
            id={"e" + example.id}
            type={example.type}
            link={example.link}
          />
        </Row>
      )
    );
  };

  const video = (
    <>
      <Row>
        <span className="primary">{t("welcome.video.caption")}</span>
      </Row>
      <Row className="video">
        <Video
          src={t("welcome.video.link")}
          title={t("welcome.video.caption")}
        />
      </Row>
    </>
  );

  return (
    <>
      <Anchor id="welcome">{t("welcome.anchor")}</Anchor>
      <div className="m-5">
        <Container fluid>
          <Row className="align-items-start">
            <Col>
              <Row>
                <div>
                  <Trans
                    i18nKey="welcome.text"
                    components={{
                      COI: <span className="coi" />,
                      WRITTEN: <span className="written" />,
                      PLAN: <span className="plan" />,
                      ALINK: <ALink />,
                      LINK: <Link />,
                      OL: <ol />,
                      UL: <ul />,
                      LI: <li />,
                      hr: <hr />,
                    }}
                  />
                </div>
                <div>
                  <Trans i18nKey="welcome.accessibility" />
                </div>
              </Row>
              {isMobileOnly && t("welcome.video.enabled") && video}
            </Col>
            {!isMobileOnly && t("welcome.video.enabled") && (
              <>
                <Col xs="auto"></Col>
                <Col xs={5}>{video}</Col>
              </>
            )}
          </Row>
        </Container>
        {t("welcome.explanations.enabled") && (
          <>
            <div
              className={
                t("welcome.explanations.label.center") ? "text-center" : ""
              }
            >
              <strong>{t("welcome.explanations.label.text")}</strong>
            </div>

            <div
              className="text-center button-group"
              style={{ marginTop: "2.5rem" }}
            >
              {[
                "written",
                "plan",
                t("welcome.explanations.coi.enabled") && "coi",
              ]
                .filter((t) => t)
                .map((type, i) => (
                  <Button
                    variant={type}
                    onClick={() => setExpansionType(type)}
                    key={i}
                  >
                    <strong>{t(`welcome.explanations.${type}.button`)}</strong>
                  </Button>
                ))}
            </div>
            {expansionType && (
              <div className="expansion">
                <Container>
                  <Row className="align-items-center">
                    <Col>
                      <Row>
                        <p>
                          <Trans
                            i18nKey={`welcome.explanations.${expansionType}.text`}
                            components={{
                              COI: <span className={"coi"} />,
                              PLAN: <span className={"plan"} />,
                              WRITTEN: <span className={"written"} />,
                              OL: <ol />,
                              UL: <ul />,
                              LI: <li />,
                            }}
                          />
                        </p>
                        <p>
                          <JLink type={expansionType} />
                        </p>
                      </Row>
                      {isMobileOnly && exampleCard(expansionType)}
                    </Col>
                    {!isMobileOnly && (
                      <>
                        <Col xs="auto"></Col>
                        <Col xs="auto">{exampleCard(expansionType)}</Col>
                      </>
                    )}
                  </Row>
                </Container>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Welcome;

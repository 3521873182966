import ALink from "../ALink/ALink.jsx";
import Anchor from "../Anchor/Anchor.jsx";
import GalleryCardIcon from "../GalleryItem/GalleryCardIcon.jsx";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation, Trans } from "react-i18next";
import { Table, Th } from '../ALink/ALink.jsx';

const Rules = () => {
  const { t } = useTranslation();
  const enacted_ush = t("examples:enacted_ush", { returnObjects: true });

  return (
   
    <>
      <Anchor id="rules">{t("rules.anchor")}</Anchor>
      <div className="m-5">
        <Trans
          i18nKey="rules.text"
          components={{
            COI: <span className="coi" />,
            WRITTEN: <span className="written" />,
            PLAN: <span className="plan" />,
            ALINK: <ALink />,
            small: <small />,
            OL: <ol />,
            UL: <ul />,
            LI: <li />,
            TABLE: <Table />,
            TBODY: <tbody />,
            TR: <tr />,
            TD: <td />,
            TH: <Th />,
          }}
        />
      </div>
      {enacted_ush && <div className="pinned">
        <Container>
          <Col>
            <Row>
              <GalleryCardIcon
                title={enacted_ush.title}
                id={"e" + enacted_ush.id}
                type={enacted_ush.type}
                link={enacted_ush.link}
              />
            </Row>
          </Col>
        </Container>
      </div>}
    </>
  );
};

export default Rules;

import Badge from "react-bootstrap/Badge";

const getType = (type) => (type === "other" ? "written" : type);

const GalleryListItem = (props) => {
  const { handleTagClick } = props;
  const { type, id, title, first, last, city, datetime } = props.plan;
  const tags = props.plan.tags || [];
  const numberofcomments = props.plan.numberofcomments || 0;

  return (
    <tr>
      <td className={getType(type)}>
        <a href={"/submission/" + type[0] + id}>
          <strong>{title}</strong>
        </a>
      </td>

      <td>
        {first} {last}
        {city ? <> – {city}</> : ""}
      </td>
      <td>{new Date(datetime).toLocaleDateString()}</td>
      <td>{type[0] + id}</td>
      <td>
        <div className="tags-expo">
          {tags.map((tag, i) => (
            <button
              className="post-tag"
              onClick={(e) => handleTagClick(e, tag)}
              key={i}
            >
              #{tag}
            </button>
          ))}
        </div>
      </td>
      <td>
        <Badge variant={getType(type)}>{numberofcomments || 0}</Badge>
      </td>
    </tr>
  );
};

export default GalleryListItem;

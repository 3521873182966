import React from "react";

// import Submissions from "../Submissions/Submissions.jsx";
import GalleryCardIcon from "../GalleryItem/GalleryCardIcon.jsx";
import CardDeck from "react-bootstrap/CardDeck";
import Feedback from "../Feedback/Feedback.jsx";
import FormParent from "../FormParent/FormParent.jsx";
// import AdvancedSearch from "../Search/AdvancedSearch.jsx";
import Anchor from "../Anchor/Anchor.jsx";
import { useTranslation, Trans } from "react-i18next";
import  { Link, Table,  Thead } from "../ALink/ALink.jsx";

const DraftPlanPage = ({ refresh, triggerRefresh }) => {
  const { t } = useTranslation();
  const draftPlans =
    t("drafts:plans", { returnObjects: true }).filter((p) => p.show) || [];

  return (
    <div className="details">
      <Anchor>{t("drafts.anchor")}</Anchor>
      <Trans
                    i18nKey="drafts.text"
                    components={{
                      LINK: <Link />,
                      table: <Table className="table"/>,
                      tbody: <tbody />,
                      thead: <Thead className="thead-light"/>,
                      tr: <tr />,
                      th: <th />,
                      td: <td style={{textAlign:'center'}}/>
                    }}
                  />
      <div className="m-5">
        <CardDeck className="gallery icons">
          {draftPlans.map((plan, i) => (
            <li key={i} className="listItem">
              <GalleryCardIcon
                title={plan.title}
                id={plan.id}
                type={plan.type}
                link={plan.link}
              />
            </li>
          ))}
        </CardDeck>
      </div>
      {t("drafts.form.enabled") && 
        <>
          <Anchor id="form">{t("drafts.form.anchor")}</Anchor>
          <div className="m-5">
            <p>{t("submit.text")}</p>
            <Feedback />
          </div>
          <FormParent
            refresh={refresh}
            triggerRefresh={triggerRefresh}
            formType={"drafts"}
          />
        </>
      }
    </div>
  );
};

export default DraftPlanPage;

/**
 * WARNING: If you make any changes here, make comparable change to the copy that is in public
 */
const {buildinfo} = require('./buildinfo')

const QA = "https://ik3ewh40tg.execute-api.us-east-2.amazonaws.com/qa/submissions"
// TODO: point to prod backend
const PROD = "https://k61e3cz2ni.execute-api.us-east-2.amazonaws.com/prod/submissions"

/**
 * Determines which backend is used for each organization.
 */
const ENDPOINT_MAP = {
  local: {
    'alaska': QA,
    'massachusetts': QA,
    'mggg' : QA,
    'michigan': QA,
    'missouri': QA,
    'newmexico': QA,
    'ohio' : QA,
    'texas': QA,
    'utah': QA,
    'virginia': QA,
    'wisconsin': QA,
    'pennsylvania': QA,
    'florida': QA,
    'contracosta': QA,
    'minneapolis': QA,
    'indiana': QA,
    'sandiego': QA
  },
  qa: {
    'alaska': QA,
    'massachusetts' : QA,
    'mggg' : QA,
    'michigan': QA,
    'missouri': QA,
    'newmexico': QA,
    'ohio' : QA,
    'texas': QA,
    'utah': QA,
    'virginia': QA,
    'wisconsin': QA,
    'pennsylvania': QA,
    'florida': QA,
    'contracosta': QA,
    'minneapolis': QA,
    'indiana': QA,
    'sandiego': QA
  },
  prod: {
    'alaska': PROD,
    'massachusetts' : PROD,
    'mggg' : PROD,
    'michigan': PROD,
    'missouri': PROD,
    'newmexico': PROD,
    'ohio' : PROD,
    'texas': PROD,
    'utah': PROD,
    'virginia': PROD,
    'wisconsin': PROD,
    'pennsylvania': PROD,
    'florida': PROD,
    'contracosta': PROD,
    'indiana': PROD,
    'minneapolis': PROD,
    'sandiego': PROD
  }

}

let ORGANIZATION
let ENDPOINT;
function getBranch() {
  console.log('buildinfo '+ JSON.stringify(buildinfo))
  return (buildinfo ? buildinfo.branch : 'qa') || 'qa'
}
function setEndpoint(org) {
  if (org) {
    const branch = getBranch()
    if (branch in ENDPOINT_MAP) {
      if (org in ENDPOINT_MAP[branch]) {
          ENDPOINT = ENDPOINT_MAP[branch][org]
          ORGANIZATION = org
          console.log(`ORG changed to ${org}`)
      } else {
        console.log(`Unknown endpoint for branch ${branch} org ${org}`)
      }
    } else {
        console.log(`Unknown branch ${branch} `)
    }
  }
}
function getEndpoint() {
  if (!ORGANIZATION) initializeOrganization()
  return ENDPOINT
}
function getOrganization() {
  if (!ORGANIZATION) initializeOrganization()
  return ORGANIZATION
}

function initializeOrganization() {
  function getOrgFromHost(host) {
    // Support www.qa-portal.ohio-mapping.org and www.portal.ohio-mapping.org
    // and         qa-portal.ohio-mapping.org and portal.ohio-mapping.org
    let regex = /(www\.)?(\w*)-?portal.(\w+)-mapping.org/i
    let matches =  host.match(regex)
    if (matches) {
      return matches[3]
    } else {
      // Support www.ohio-mapping.org and ohio-mapping.org as prod envs
      let regex = /(www\.)?(\w+)-mapping.org/i
      let matches =  host.match(regex)
      if (matches) {
        return matches[2]
      } else {
      return null
      }
    }
  }
  function getOrgFromLocalStorage() {
    if (window.localStorage && window.localStorage.getItem ) {
      return window.localStorage.getItem('organization')
    }
  }
  function setOrgToLocalStorage(org) {
    if (window.localStorage && window.localStorage.setItem ) window.localStorage.setItem('organization', org)
  }
  const params = new URLSearchParams(new URL(window.location.href).search)
  const orgFromParam = params.get('organization')
  if (orgFromParam) {
    setOrgToLocalStorage(orgFromParam)
    window.localStorage.setItem('language', 'en')
    // redirect to root
    window.location.href = "/"
  } else {
    const orgFromHost = getOrgFromHost(new URL(window.location.href).hostname)
    console.log(`ORG param:${orgFromParam} host:${orgFromHost} localStorage:${getOrgFromLocalStorage()}`)
    let org = orgFromParam || orgFromHost || getOrgFromLocalStorage()
    if (org) {
      org = org.toLowerCase()
      setEndpoint(org)
      setOrgToLocalStorage(org)
    }
    return org
  }
}

if (typeof module !== 'undefined'  && module && module.exports) {
  module.exports.getBranch = getBranch
  module.exports.getEndpoint = getEndpoint
  module.exports.getOrganization = getOrganization
  module.exports.initializeOrganization = initializeOrganization
}

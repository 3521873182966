import "./HowToDraw.scss";

import { isMobileOnly } from "react-device-detect";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Anchor from "../Anchor/Anchor.jsx";
import Video from "../Video/Video.jsx";
import ALink from "../ALink/ALink.jsx";

import { useTranslation, Trans } from "react-i18next";

import { getBranch } from "../../organizations";
import React from "react";
const isQA = getBranch() === "qa";

const DistrictrButtons = () => {
  const { t } = useTranslation();
  const modules = t("howtodraw.districtrButtons.modules", {
    returnObjects: true,
  });
  const state_abbrev = t("state.abbreviation");

  return (
    <>
      <p>{t("howtodraw.districtrButtons.text")}</p>

      <div className="text-center m-5 button-group">
        {modules.map((module, i) => (
          <React.Fragment key={i}>
            <Button
              variant={module.variant}
              href={`https://districtr.org/${
                isQA ? "qa-" : ""
              }portal/${state_abbrev}/${module.link}`}
              target="_blank"
              rel="noreferrer"
            >
              <strong>
                {module.text} <br />{" "}
                {t("howtodraw.districtrButtons.leavingSite")}
              </strong>
            </Button>{" "}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

const HowToDraw = () => {
  const { t } = useTranslation();

  const tutorialVideo = (type) => (
    <>
      <Row>
        <span className={type}>{t(`howtodraw.videos.${type}.title`)}</span>
      </Row>
      <Row className={!isMobileOnly && "video"}>
        <Video
          src={t(`howtodraw.videos.${type}.link`)}
          title={t(`howtodraw.videos.${type}.title`)}
        />
      </Row>
    </>
  );

  return (
    <>
      <Anchor id="how">{t("howtodraw.anchor")}</Anchor>
      <div className="m-5">
        <p>
          <Trans i18nKey="howtodraw.text" components={{ ALINK: <ALink /> }} />
        </p>
        <p>
          {t("howtodraw.rules.org.enabled") && (
            <Trans
              i18nKey="howtodraw.rules.org.text"
              components={{ ALINK: <ALink /> }}
            />
          )}
        </p>
        <p>
          {t("howtodraw.rules.general.enabled") && (
            <Trans
              i18nKey="howtodraw.rules.general.text"
              components={{ ALINK: <ALink /> }}
            />
          )}
        </p>

        {t("howtodraw.districtrButtons.placement") === "before" && (
          <DistrictrButtons />
        )}

        {t("howtodraw.videos.enabled") && (
          <div style={{ marginBottom: "3.5rem" }}>
            <p>{t("howtodraw.videos.explainer")}</p>
            <Container fluid>
              {isMobileOnly ? (
                <>
                  {t("howtodraw.videos.plan.enabled") && tutorialVideo("plan")}
                  {t("howtodraw.videos.coi.enabled") && tutorialVideo("coi")}
                </>
              ) : (
                <Row className="align-items-center">
                  <Col>
                    {t("howtodraw.videos.coi.enabled") && tutorialVideo("coi")}
                  </Col>
                  <Col xs="auto"></Col>
                  <Col className="centering">
                    {t("howtodraw.videos.plan.enabled") &&
                      tutorialVideo("plan")}
                  </Col>
                  {!t("howtodraw.videos.coi.enabled") && <Col />}
                </Row>
              )}
            </Container>
          </div>
        )}
        <div>
          <p>
            <Trans
              i18nKey="howtodraw.training.text"
              components={{ ALINK: <ALink /> }}
            />{" "}
            <Trans
              i18nKey="howtodraw.training.additional"
              components={{ ALINK: <ALink /> }}
            />
          </p>
          {t("howtodraw.districtrButtons.placement") === "after" && (
            <DistrictrButtons />
          )}
        </div>
      </div>
    </>
  );
};

export default HowToDraw;

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

const Navigation = ({ switchLang }) => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar fixed="top" bg="primary" variant="dark" expand="lg">
        <Navbar.Brand href="/">{t("navbar.title")}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {t("order", { returnObjects: true }).map((section, i) => (
              <Nav.Link href={`/#${section}`} key={i}>
                {t(`navbar.${section}`)}
              </Nav.Link>
            ))}
            <Nav.Link href={"/search"}>{t("buttons.search")}</Nav.Link>
            {t("drafts.enabled") && (
              <Nav.Link href={"/plans"}>{t("buttons.drafts")}</Nav.Link>
            )}
          </Nav>
          {(t("languages.spanish") ||
            t("languages.navajo") ||
            t("languages.creole")) && (
            <Nav>
              <Button onClick={() => switchLang("en")}>English</Button>
              {t("languages.spanish") && (
                <Button onClick={() => switchLang("es")}>Español</Button>
              )}
              {t("languages.navajo") && (
                <Button onClick={() => switchLang("nv")}>Navajo</Button>
              )}
              {t("languages.creole") && (
                <Button onClick={() => switchLang("ht")}>Creole</Button>
              )}
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Navigation;

import Submissions from "../Submissions/Submissions.jsx";
import InputTags from "../InputTags/InputTags.jsx";
import Anchor from "../Anchor/Anchor.jsx";
import PinnedPlans from "../PinnedPlans/PinnedPlans.jsx";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const Gallery = (props) => {
  const { refresh } = props;

  const [numMatches, setNumMatches] = useState(null);
  const [filterTags, setFilterTags] = useState(
    window.location.href.includes("tag=")
      ? [
          window.location.href
            .split("tag=")[1]
            .split("#")[0]
            .replace(/%20/g, " "),
        ]
      : []
  );
  const { t } = useTranslation();

  return (
    <>
      <Anchor id="gallery">{t("gallery.anchor")}</Anchor>
      <div className="m-5">
        {t("inputTags.enabled") && (
          <>
            <strong>{t("gallery.filter")}</strong>
            <InputTags
              tags={filterTags}
              setTags={setFilterTags}
              controlId="filtertags"
            />
          </>
        )}
        <div className="text-right">
          <Button variant="primary" href="/search">
            {t("gallery.advancedSearch")}
          </Button>
        </div>
        {t("gallery.pinned.enabled") && <PinnedPlans />}
        <Submissions
          searchFields={t("gallery.initialSearch", {returnObjects: true})}
          filterTags={filterTags}
          setFilterTags={setFilterTags}
          numMatches={numMatches}
          setNumMatches={setNumMatches}
          refresh={refresh}
        />
      </div>
    </>
  );
};

export default Gallery;

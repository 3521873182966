import React from "react";
import { isMobileOnly } from "react-device-detect";

// import { useHistory } from "react-router-dom"

import Comments from "../Comments/Comments.jsx";
import Derivatives from "../Derivatives/Derivatives.jsx";
import Anchor from "../Anchor/Anchor.jsx";

import "./DetailPage.scss";

import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { getDetails, movePage, planPreview } from "../../api.js";
import clearText from "../../clearText";
import i18next from "i18next";
import { withTranslation } from "react-i18next";

class DetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      image: i18next.t("state.thumbnail"),
      plan: {},
      id: window.location.href.split("/").slice(-1)[0].split("?")[0],
      isExample: false,
      isDraft: false,
      iframe: "about:blank",
      districttype: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    /*
    id can have the form
    we123  --> Example with id 123
    w456   --> Real submission in the database with id 456
*/
    const coi = i18next.t("examples:coi", { returnObjects: true });
    const plans = i18next.t("examples:plan", { returnObjects: true });
    const written = i18next.t("examples:written", { returnObjects: true });
    const enacted_ush = i18next.t("examples:enacted_ush", {
      returnObjects: true,
    });
    const enacted_senate = i18next.t("examples:enacted_senate", {
      returnObjects: true,
    });
    const enacted_house = i18next.t("examples:enacted_house", {
      returnObjects: true,
    });

    const draftPlans = i18next.t("drafts:plans", { returnObjects: true }) || [];

    const examples = [
      coi,
      plans,
      written,
      enacted_ush,
      enacted_senate,
      enacted_house,
    ];

    if (this.state.id[1] === "e") {
      const exampleId = this.state.id.slice(2);
      // Convert e.id toString in case it is an int
      const p =
        examples.find((e) => e.id && e.id.toString() === exampleId) || {};
      this.setState({ plan: p });
      this.setState({ isExample: true });
      if (p.type === "plan" || p.type === "coi") {
        let did = p.link.split("/").slice(-1)[0];
        did = did.split("?")[0].split("&")[0];
        if (did.length) {
          if (
            window.screen &&
            window.screen.width &&
            window.screen.width < 600
          ) {
            return;
          }
          planPreview(did).then((api) => {
            if (api.screenshot) {
              this.setState({
                image: api.screenshot,
                iframe: "https://districtr.org/embedded?id=" + did,
              });
            } else {
              this.setState({
                iframe: "https://districtr.org/embedded?id=" + did,
              });
            }
          });
        }
      }
    } else if (this.state.id[0] === "d") {
      const exampleId = this.state.id.slice(1);
      // Convert e.id toString in case it is an int
      const p =
        draftPlans.find((e) => e.id && e.id.toString() === exampleId) || {};
      console.log(p);
      this.setState({ plan: p });
      this.setState({ isDraft: true });
      if (p.type === "draft" || p.type === "plan" || p.type === "coi") {
        let did = p.link.split("/").slice(-1)[0];
        did = did.split("?")[0].split("&")[0];
        if (did.length) {
          if (
            window.screen &&
            window.screen.width &&
            window.screen.width < 600
          ) {
            return;
          }
          planPreview(did).then((api) => {
            if (api.screenshot) {
              this.setState({
                image: api.screenshot,
                iframe: "https://districtr.org/embedded?id=" + did,
              });
            } else {
              this.setState({
                iframe: "https://districtr.org/embedded?id=" + did,
              });
            }
          });
        }
      }
    } else {
      getDetails(this.state.id.substring(1))
        .then((res) => {
          let plan = res.submissionDetails;
          let stateful = {};
          stateful.plan = plan;

          if (plan.type === "plan" || plan.type === "coi") {
            let did = plan.link.split("/").slice(-1)[0];
            did = did.split("?")[0].split("&")[0];
            if (did.length && !isNaN(1 * did)) {
              if (!isMobileOnly) {
                stateful.iframe = "https://districtr.org/embedded?id=" + did;
              }

              planPreview(did).then((api) => {
                this.setState({
                  image: api.screenshot || null,
                  districttype:
                    api.plan && api.plan.problem && api.plan.problem.pluralNoun
                      ? api.plan.problem.pluralNoun
                      : null,
                });
              });
            }
          }
          this.setState(stateful);
        })
        .catch((err) => {
          console.error("There was an error!", err);
        });
    }

    this.setState({ loaded: true });
  }

  loadPrevious(e) {
    movePage(this.state.id.substring(1), -1).then((res) => {
      if (res && res.nextid && res.nextid.length) {
        window.location.href = "/submission/x" + res.nextid[0].id;
      } else {
        window.alert(i18next.t("details.noPrevious"));
        e.target.style.visibility = "hidden";
      }
    });
  }
  loadNext(e) {
    movePage(this.state.id.substring(1), 1).then((res) => {
      if (res && res.nextid && res.nextid.length) {
        window.location.href = "/submission/x" + res.nextid[0].id;
      } else {
        window.alert(i18next.t("details.noNext"));
        e.target.style.visibility = "hidden";
      }
    });
  }

  render() {
    const { loaded, plan, isExample, isDraft, districttype } = this.state;

    // get source of link (Representable / DRA)
    let link;
    const allowLinks = [
      "www.representable.org",
      "representable.org",
      "www.davesredistricting.org",
      "davesredistricting.org",
      "app.districtbuilder.org",
      "districtbuilder.org",
    ];
    if (plan && plan.link) {
      try {
        link = new window.URL(plan.link);
      } catch (e) {
        link = plan.link;
      }
    }

    return (
      <div className="details">
        <Container fluid>
          {loaded ? (
            ""
          ) : (
            <Row className="justify-content-md-center">
              <Alert variant="info">
                Loading... <Spinner animation="border" role="status" />
              </Alert>
            </Row>
          )}

          {!isDraft && (
            <div>
              <button
                className="btn page-btn"
                onClick={this.loadNext.bind(this)}
              >
                &larr; {this.props.t("details.next")}
              </button>
              <button
                className="btn page-btn"
                style={{ float: "right" }}
                onClick={this.loadPrevious.bind(this)}
              >
                {this.props.t("details.previous")} &rarr;
              </button>
            </div>
          )}

          <Col>
            <Row>
              <h1>{clearText(plan.title)}</h1>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Anchor size="1.2">
                    {this.props.t("details.basic.anchor")}
                  </Anchor>
                </Row>
                <Row>
                  <div>
                    {this.props.t("details.basic.submitter")}:{" "}
                    {clearText(plan.first) + " " + clearText(plan.last)} <br />
                    {this.props.t("details.basic.location")}:{" "}
                    {clearText(plan.city)} <br />
                    {this.props.t("details.basic.submitted")}:{" "}
                    {plan.datetime
                      ? new Date(plan.datetime).toLocaleDateString()
                      : ""}{" "}
                    <br />
                    {this.props.t("details.basic.type")}: {clearText(plan.type)}{" "}
                    <br />
                    {plan.type === "plan" && districttype ? (
                      <>
                        {clearText(districttype.replace("Reapportioned ", ""))}
                        <br />
                      </>
                    ) : null}
                    {plan.draft && (
                      <>
                        {this.props.t("drafts.modification")}
                        {plan.draft}
                        <br />
                      </>
                    )}
                    {this.props.t("details.basic.id")}:{" "}
                    {plan.type ? plan.type[0] + plan.id : ""} <br />
                  </div>
                </Row>
                <Row>
                  <Anchor size="1.2">
                    {this.props.t("details.tags.anchor")}
                  </Anchor>
                </Row>
                <Row>
                  <div className="tags-expo">
                    {(plan.tags || []).map((tag, i) => (
                      <a
                        className="post-tag"
                        href={"/?tag=" + clearText(tag) + "#gallery"}
                        key={i}
                      >
                        #{clearText(tag)}&nbsp;&nbsp;
                      </a>
                    ))}
                  </div>
                </Row>
                {plan.type === "plan" ||
                plan.type === "coi" ||
                plan.type === "draft" ? (
                  <>
                    <Row>
                      <Anchor size="1.2">
                        {this.props.t("details.more.anchor")}
                      </Anchor>
                    </Row>
                    <Row>
                      <Col>
                        {clearText(plan.text)
                          .split(/\n/)
                          .map((section, i) => (
                            <div className="mb-2" key={i}>
                              {section.length ? section : <br />}
                            </div>
                          ))}
                        {plan.ancestor && (
                          <p>
                            This is descended from the original{" "}
                            <a
                              className="link link-primary"
                              href={plan.ancestor.link}
                            >
                              {plan.ancestor.name}
                            </a>{" "}
                            draft map.
                          </p>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </Col>
              <Col xs={1}></Col>
              {plan.type === "plan" ||
              plan.type === "coi" ||
              plan.type === "draft" ? (
                <Col>
                  <Row>
                    <Anchor size="1.2">
                      {this.props.t("details.map.anchor")}
                    </Anchor>
                    <p>{this.props.t("details.map.explainer")}</p>
                  </Row>
                  <Row>
                    <iframe
                      title="Districtr Preview"
                      id="districtr-box"
                      style={{ height: 600, width: "100%", margin: "2rem 0" }}
                      src={this.state.iframe}
                      frameBorder="0"
                    ></iframe>
                  </Row>
                  <Row className="align-items-center">
                    <Button
                      href={
                        (plan.link.includes("portal")
                          ? plan.link
                          : plan.link + "?portal") +
                        (isDraft ? `&draft=d${plan.id}` : "")
                      }
                      target="_blank"
                      id="districtrButton"
                      rel="noreferrer"
                    >
                      {this.props.t("details.map.button")}
                    </Button>
                    <div>{this.props.t("details.map.districtr")}</div>
                  </Row>
                </Col>
              ) : (
                <Col>
                  {plan.type === "file" && (
                    <Row>
                      <Anchor size="1.2">Sent File</Anchor>
                    </Row>
                  )}
                  {plan.type === "other" && (
                    <>
                      <Row>
                        <Anchor size="1.2">
                          {i18next.t("details.external.anchor")}
                        </Anchor>
                      </Row>
                      <Row>
                        <p>
                          {allowLinks.includes(link.host || link.hostname) ? (
                            <a
                              className="link link-primary"
                              href={plan.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {plan.link}
                            </a>
                          ) : (
                            clearText(plan.link)
                          )}
                        </p>
                      </Row>
                      <Row>
                        <div className="p-2 text-muted">
                          {allowLinks.includes(link.host || link.hostname)
                            ? ""
                            : "(This is a user submitted link. Open external links with caution)"}
                        </div>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Anchor size="1.2">
                      {i18next.t("details.written.anchor")}
                    </Anchor>
                  </Row>
                  <Row>
                    <Col>
                      {clearText(plan.text)
                        .split(/\n/)
                        .map((section, i) => (
                          <div key={i}>{section.length ? section : <br />}</div>
                        ))}
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            <hr />
            {this.props.t("details.comments.enabled") && <Row className="justify-content-md-center">
            {isExample ? null : isDraft ? (
                <a
                  className="btn btn-primary"
                  href={"/plans?commentid=" + plan.type[0] + plan.id + "#form"}
                >
                  {this.props.t("details.comments.draft-button")}
                </a>
              ) : (
                <a
                  className="btn btn-primary"
                  href={
                    "/?commentid=" + (plan.type || "")[0] + plan.id + "#form"
                  }
                >
                  {this.props.t("details.comments.button")}
                </a>
              )}
            </Row>}
            <Row>
              <Col>
                <Row>
                  <Anchor size="1.2">
                    {this.props.t("details.comments.anchor")}
                  </Anchor>
                </Row>
                <Row>
                  <Comments
                    comments={plan.comments || []}
                    // commenttags={plan.commenttags || []}
                    id={plan.type ? plan.type[0] + plan.id : ""}
                    isExample={isExample}
                    isDraft={isDraft}
                  />{" "}
                </Row>
              </Col>
              {isDraft && (
                <>
                  <Col xs={1}></Col>
                  <Col>
                    <Row>
                      <Anchor size="1.2">
                        {this.props.t("details.derivatives.anchor")}
                      </Anchor>
                    </Row>
                    <Row>
                      <Derivatives draft={plan.type[0] + plan.id} /> {this.id}
                    </Row>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(DetailPage);

//import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { getPreviewsBySearch } from "../../api.js";
import GalleryCardIcon from "../GalleryItem/GalleryCardIcon";

const Derivatives = ({ draft }) => {
  //const { t } = useTranslation();
  const [previews, setPreviews] = useState([]);
  const [numMatches, setNumMatches] = useState(0);

  useEffect(() => {
    getPreviewsBySearch("", "", "", "", "", "", draft).then((res) => {
      setNumMatches(res.matches || 0);
      setPreviews(Object.values(res.submissionPreviews));
    });
    console.log(numMatches);
  }, []);

  return (
    <div>
      {previews.map((preview, i) => (
        <div key={i} className="derivative">
          <GalleryCardIcon
            id={preview.id}
            type={preview.type}
            link={preview.link}
            title={preview.title}
          />
        </div>
      ))}
    </div>
  );
};

export default Derivatives;

import "./Anchor.scss";

const Anchor = (props) => {
  const { children, id, size } = props;
  return (
    <div>
      <div className="jump" id={id}></div>
      <div className="anchor" style={{ fontSize: `${size || 1.5}em` }}>
        {!size || 1.5 === size ? <h1> {children} </h1> : <h2> {children} </h2>}
      </div>
    </div>
  );
};
export default Anchor;

import Anchor from "../Anchor/Anchor.jsx";
import { useTranslation } from "react-i18next";
import FormParent from "../FormParent/FormParent.jsx";
import Feedback from "../Feedback/Feedback.jsx";

const Submit = ({ refresh, triggerRefresh }) => {
  const { t } = useTranslation();
  return (
    <>
      <Anchor id="submit">{t("submit.anchor")}</Anchor>
      <div className="m-5">
        <p>{t("submit.text")}</p>
        <Feedback />
      </div>
      <FormParent refresh={refresh} triggerRefresh={triggerRefresh} />
    </>
  );
};

export default Submit;

import { JLink } from "../ALink/ALink.jsx";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Alert from "react-bootstrap/Alert";
import TabContent from "react-bootstrap/TabContent";
import { useState, useEffect } from "react";
import { planPreview } from "../../api";
import { useTranslation } from "react-i18next";

const FormTabs = (props) => {
  const { t } = useTranslation();
  const {
    register,
    setValue,
    prefillid,
    setPrefillid,
    type,
    setType,
    formType,
    districtrContent,
    setDistrictrContent,
    setBlockSubmit,
  } = props;

  const [districtrID, setDistrictrID] = useState(
    prefillid &&
      !window.location.href.includes("comment") &&
      !window.location.href.includes("draft")
      ? new window.URL(prefillid).pathname.split("/").slice(-1)[0]
      : null
  );

  const draftPlans = (t("drafts:plans", { returnObjects: true }) || [])
    .filter((plan) => plan.show)
    ;

  const [invalidDistrictrLink, setInvalidDistrictrLink] = useState(false);

  const DistrictrCheck = (props) => {
    const { districtrID, districtrContent, name, pluralName } = props;
    if (districtrContent && districtrContent.error) {
      return (
        <Alert variant="danger">
          {districtrContent.error} {t("support")}
        </Alert>
      );
    }
    if (districtrID && districtrContent) {
      return (
        <div>
          Found&nbsp;
          <img
            src={districtrContent.img}
            width="100"
            alt="Districtr preview corresponding to the provided link"
          />
          <Form.Control
            aria-label="Hidden district type code"
            ref={register}
            name="districttype"
            defaultValue={districtrContent.districttype}
            style={{ display: "none" }}
          />
          {districtrContent.districts}{" "}
          {districtrContent.districts === 1 ? name : pluralName}
          {districtrContent.landmarks ? (
            <>
              {" "}
              and {districtrContent.landmarks}{" "}
              {districtrContent.landmarks === 1 ? "place" : "places"}
            </>
          ) : null}
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (districtrID) {
      planPreview(districtrID.trim(), new Date()).then((p) => {
        // let districtSet = new Set(),
        let landmarkCount = 0;
        if (p.plan) {
          // (Object.values(p.plan.assignment || {}) || []).forEach((val) => {
          //   if (val && val.length) {
          //     val
          //       .filter((v) => v || v === 0)
          //       .forEach((v) => districtSet.add(v));
          //   } else if (val || val === 0) {
          //     districtSet.add(val);
          //   }
          // });
        } else {
          setBlockSubmit(true);
          return setDistrictrContent({
            error: t("submit.submission.preview.idNotFound"),
          });
        }
        if (
          !p.plan ||
          !p.plan.place ||
          (p.plan.place.state || "").toLowerCase() !==
            t("state.name").toLowerCase()
        ) {
          setBlockSubmit(true);
          return setDistrictrContent({
            error: t("submit.submission.preview.planNotFound"),
          });
        }/* This code is failing because p.plan.units
            is no longer being populated by the back end.
            No good way to detect 2020 NM precincts with the current api.
         else if (
          p.plan.place.state === "New Mexico" &&
          !p.plan.units.name.includes("2020")
        ) {
          setBlockSubmit(true);
          return setDistrictrContent({
            error: t("submit.submission.preview.planNot2020"),
          });
        } */
        setBlockSubmit(false);
        if (
          p.plan &&
          p.plan.place &&
          p.plan.place.landmarks &&
          p.plan.place.landmarks.data &&
          p.plan.place.landmarks.data.features
        ) {
          landmarkCount = p.plan.place.landmarks.data.features.length;
        }
        if (!p.screenshot && districtrID.length < 14) {
          // reload in 1/3 second
          setTimeout(() => {
            setDistrictrID(districtrID + " ");
          }, 300);
        }
        setDistrictrContent({
          img: p.screenshot || "",
          // districts: districtSet.size,
          landmarks: landmarkCount,
          districttype: {
            "Reapportioned Congressional Districts": "ush",
            "Congressional Districts": "ush",
            "State Senate Districts": "senate",
            "State House Districts": "house",
            "State Assembly Districts": "house",
            "Senatorial Districts": "senate",
            "House Districts": "house",
            "House of Delegates Districts": "house",
            "State Delegate Districts": "house",
            "State Legislature Districts": "senate",
            "Legislative Districts": "senate",
            "City Park Districts": "citypark",    
            "Public Education Commission Districts" : "stateschool",
            "County Commission Districts": "countycommission",
            "City Council Wards": "citycouncil",
            "Council Districts" : "citycouncil",
            "City Council Districts" : "citycouncil",
            "Community": "community",
            "Supervisorial Districts": "supervisorial"
          }[p.plan.problem.pluralNoun],
        });
      });
    }
  }, [t, districtrID, setBlockSubmit, setDistrictrContent]);

  const handleSelect = (t) => {
    setType(t);
    setPrefillid("");
    setInvalidDistrictrLink(false);
    setDistrictrContent(null);
    setBlockSubmit(false);
  };
  const isStartOfValidDistrictrLink = (url) => {
    var validity = false;
    url = url.toLowerCase()
    if (!isNaN(parseInt(url))) {
      // accept a pure integer
      validity = true
    } else {
      var host
      try {
        const myurl = new window.URL(url)
        host = myurl.host || myurl.hostname;
        if ("districtr.org".startsWith(host) || "www.districtr.org".startsWith(host)) validity = true;
        else validity = false;
      } catch (e) {
        if ("http://districtr.org".startsWith(url) || "http://www.districtr.org".startsWith(host) ||
        "https://districtr.org".startsWith(url) || "https://www.districtr.org".startsWith(host)) validity = true;
        else validity = false;
      }
    }
    setInvalidDistrictrLink(!validity);
    setBlockSubmit(!validity);
    if (validity) setDistrictrContent({ error: null });
  }

  const isValidDistrictrLink = (url, type) => {
    let disid,
      validity = false;
    if (!isNaN(parseInt(url))) {
      // Allow user to type just the districtr ID. We convert to a full URL  
      url = `https://districtr.org/${type}/${url}`
      setValue('link',url)
    }
    try {
      const myurl = new window.URL(url.toLowerCase()),
        host = myurl.host || myurl.hostname;
      disid = myurl.pathname.split("/").slice(-1)[0];
      validity =
        !isNaN(1 * disid) &&
        disid * 1 >= 1000 &&
        ["districtr.org", "www.districtr.org"].includes(host);
      if (!validity) {
        setDistrictrContent({
          error: t("submit.submission.preview.idNotFound"),
        });
      } else if (
        type &&
        !myurl.pathname.includes("/" + type + "/") &&
        !myurl.pathname.includes("/edit/")
      ) {
        if (type === "plan") {
          setDistrictrContent({
            error: t("submit.submission.preview.isCOI"),
          });
        } else {
          setDistrictrContent({
            error: t("submit.submission.preview.isPlan"),
          });
        }
        validity = false;
      }
    } catch (e) {
      validity = false;
      setDistrictrContent({
        error: t("submit.submission.preview.idNotFound"),
      });
    }
    if (
      validity &&
      districtrContent &&
      (districtrContent.error || "").includes("valid")
    ) {
      setDistrictrContent({ error: null });
    }
    setInvalidDistrictrLink(!validity);
    setBlockSubmit(!validity);
    setDistrictrID(validity ? disid : null);
  };

  return (
    <>
      {formType === "drafts" ? (
        <Tabs
          transition={false}
          activeKey={type}
          onSelect={handleSelect}
          unmountOnExit
        >
          {t("drafts.form.comment.enabled") && (
            <Tab eventKey="comment" title={t("drafts.form.comment.tab")}>
              <div className="m-4">{t("drafts.form.comment.text")}</div>
              <TabContent>
                <div>
                  <Form.Group controlId="sub-commentid">
                    <Form.Label>
                      <strong>{t("drafts.form.comment.id.label")}</strong>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue={prefillid}
                      ref={register}
                      className="testimony"
                      name="draft"
                      placeholder={t("drafts.form.comment.id.placeholder")}
                      required
                      maxLength="20"
                      style={{ width: "20%" }}
                    >
                      {draftPlans.map((plan) => (
                        <option key={"d"+plan.id} value={"d"+plan.id}>d{plan.id}-{plan.title}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="sub-commenttext">
                    <Form.Label>
                      <strong>
                        {t("drafts.form.comment.testimony.label")}
                      </strong>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      ref={register}
                      name="text"
                      className="testimony"
                      placeholder={t(
                        "drafts.form.comment.testimony.placeholder"
                      )}
                      required
                      maxLength="10000"
                      onKeyPress={(e) =>
                        e.key === "Enter" ? e.stopPropagation() : null
                      }
                    />
                  </Form.Group>
                </div>
              </TabContent>
            </Tab>
          )}
          {t("drafts.form.modified-plan.enabled") && (
            <Tab eventKey="plan" title={t("drafts.form.modified-plan.tab")}>
              <div className="m-4">
                {t("drafts.form.modified-plan.text")}
                <br />
                <JLink type="plan" />
              </div>
              <TabContent>
                <Form.Group controlId="sub-commentid">
                  <Form.Label>
                    <strong>{t("drafts.form.modified-plan.id.label")}</strong>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue={new URLSearchParams(
                      window.location.search
                    ).get("draftid")}
                    ref={register}
                    className="testimony"
                    name="draft"
                    placeholder={t("drafts.form.modified-plan.id.placeholder")}
                    required
                    maxLength="10"
                    style={{ width: "20%" }}
                  >
                  {draftPlans.map((plan) => (
                    <option key={"d"+plan.id} value={"d"+plan.id}>d{plan.id}-{plan.title}</option>
                  ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="sub-district">
                  <Form.Label>
                    <strong>{t("drafts.form.modified-plan.link.label")}</strong>
                  </Form.Label>

                  <Form.Control
                    defaultValue={prefillid || ""}
                    placeholder={t(
                      "drafts.form.modified-plan.link.placeholder"
                    )}
                    onBlur={(e) =>
                      isValidDistrictrLink(e.target.value, "plan")
                    }
                    onChange={(e) =>
                      isStartOfValidDistrictrLink(e.target.value, "plan")
                    }
                    ref={register}
                    name="link"
                    required
                    className="testimony"
                    isInvalid={invalidDistrictrLink}
                    maxLength="50"
                    style={{ width: "30%" }}
                  />
                </Form.Group>

                <DistrictrCheck
                  districtrID={districtrID}
                  districtrContent={districtrContent}
                  name="district"
                  pluralName="districts"
                />

                <Form.Group controlId="else">
                  <Form.Label>
                    <strong>
                      {t("drafts.form.modified-plan.testimony.label")}
                      {t("drafts.form.modified-plan.testimony.required") && "*"}
                    </strong>
                  </Form.Label>{" "}
                  {t("drafts.form.modified-plan.testimony.text")}
                  <Form.Control
                    as="textarea"
                    ref={register}
                    name="text"
                    className="testimony"
                    placeholder={t(
                      "drafts.form.modified-plan.testimony.placeholder"
                    )}
                    required={t("drafts.form.modified-plan.testimony.required")}
                    maxLength="10000"
                    onKeyPress={(e) =>
                      e.key === "Enter" ? e.stopPropagation() : null
                    }
                  />
                </Form.Group>
              </TabContent>
            </Tab>
          )}
        </Tabs>
      ) : (
        <Tabs
          transition={false}
          activeKey={type}
          onSelect={handleSelect}
          unmountOnExit
        >
          {t("submit.submission.written.enabled") && (
            <Tab eventKey="written" title={t("submit.submission.written.tab")}>
              <div className="m-4">{t("submit.submission.written.text")}</div>
              <TabContent>
                <Form.Group controlId="sub-written">
                  <Form.Label>
                    <strong>{t("submit.submission.written.label")}</strong>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder={t("submit.submission.written.placeholder")}
                    ref={register}
                    rows={5}
                    name="text"
                    required
                    className="testimony"
                    maxLength="10000"
                    onKeyPress={(e) =>
                      e.key === "Enter" ? e.stopPropagation() : null
                    }
                  />
                </Form.Group>
              </TabContent>
            </Tab>
          )}
          {t("submit.submission.plan.enabled") && (
            <Tab eventKey="plan" title={t("submit.submission.plan.tab")}>
              <div className="m-4">
                {t("submit.submission.plan.text")}
                <br />
                <JLink type="plan" />
              </div>
              <TabContent>
                <Form.Group controlId="sub-district">
                  <Form.Label>
                    <strong>{t("submit.submission.plan.link.label")}</strong>
                  </Form.Label>

                  <Form.Control
                    defaultValue={prefillid || ""}
                    placeholder={t("submit.submission.plan.link.placeholder")}
                    onBlur={(e) =>
                      isValidDistrictrLink(e.target.value, "plan")
                    }
                    onChange={(e) =>
                      isStartOfValidDistrictrLink(e.target.value, "plan")
                    }
                    ref={register}
                    name="link"
                    required
                    className="testimony"
                    isInvalid={invalidDistrictrLink}
                    maxLength="50"
                    style={{ width: "30%" }}
                  />
                </Form.Group>

                <DistrictrCheck
                  districtrID={districtrID}
                  districtrContent={districtrContent}
                  name="district"
                  pluralName="districts"
                />

                <Form.Group controlId="else">
                  <Form.Label>
                    <strong>
                      {t("submit.submission.plan.testimony.label")}
                      {t("submit.submission.plan.testimony.required") && "*"}
                    </strong>
                  </Form.Label>{" "}
                  {t("submit.submission.plan.testimony.text")}
                  <Form.Control
                    as="textarea"
                    ref={register}
                    name="text"
                    className="testimony"
                    placeholder={t(
                      "submit.submission.plan.testimony.placeholder"
                    )}
                    required={t("submit.submission.plan.testimony.required")}
                    maxLength="10000"
                    onKeyPress={(e) =>
                      e.key === "Enter" ? e.stopPropagation() : null
                    }
                  />
                </Form.Group>
                {t("submit.submission.plan.competition.enabled") && (
                  <Form.Group controlId="else">
                    <Form.Label>
                      <strong>
                        {t("submit.submission.plan.competition.label")}
                        {t("submit.submission.plan.competition.required") &&
                          "*"}
                      </strong>
                    </Form.Label>{" "}
                    {t("submit.submission.plan.competition.text")}
                    <Form.Control
                      as="textarea"
                      ref={register}
                      name="text2"
                      className="competition"
                      placeholder={t(
                        "submit.submission.plan.competition.placeholder"
                      )}
                      required={t(
                        "submit.submission.plan.competition.required"
                      )}
                      maxLength="10000"
                      onKeyPress={(e) =>
                        e.key === "Enter" ? e.stopPropagation() : null
                      }
                    />
                  </Form.Group>
                )}
              </TabContent>
            </Tab>
          )}
          {t("submit.submission.coi.enabled") && (
            <Tab eventKey="coi" title={t("submit.submission.coi.tab")}>
              <div className="m-4">
                {t("submit.submission.coi.text")}
                <br />
                <JLink type="coi" />
              </div>
              <TabContent>
                <Form.Group controlId="sub-coi">
                  <Form.Label>
                    <strong>{t("submit.submission.coi.link.label")}</strong>
                  </Form.Label>
                  <Form.Control
                    defaultValue={prefillid || ""}
                    placeholder={t("submit.submission.coi.link.placeholder")}

                    onBlur={(e) =>
                      isValidDistrictrLink(e.target.value, "coi")
                    }
                    onChange={(e) =>
                      isStartOfValidDistrictrLink(e.target.value, "coi")
                    }
                    ref={register}
                    name="link"
                    required
                    className="testimony"
                    isInvalid={invalidDistrictrLink}
                    maxLength="50"
                    style={{ width: "30%" }}
                  />
                </Form.Group>

                <DistrictrCheck
                  districtrID={districtrID}
                  districtrContent={districtrContent}
                  name="community"
                  pluralName="communities"
                />

                <Form.Group controlId="else">
                  <Form.Label>
                    <strong>
                      {t("submit.submission.coi.testimony.label")}
                      {t("submit.submission.coi.testimony.required") && "*"}
                    </strong>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    ref={register}
                    name="text"
                    className="testimony"
                    placeholder={t(
                      "submit.submission.coi.testimony.placeholder"
                    )}
                    required={t("submit.submission.coi.testimony.required")}
                    maxLength="10000"
                    onKeyPress={(e) =>
                      e.key === "Enter" ? e.stopPropagation() : null
                    }
                  />
                </Form.Group>
              </TabContent>
            </Tab>
          )}
          {t("submit.submission.other.enabled") && (
            <Tab eventKey="other" title={t("submit.submission.other.tab")}>
              <div className="m-4">{t("submit.submission.other.text")}</div>
              <TabContent>
                <Form.Group controlId="sub-other">
                  <Form.Label>
                    <strong>{t("submit.submission.other.link.label")}*</strong>
                  </Form.Label>

                  <Form.Control
                    placeholder={t("submit.submission.other.link.placeholder")}
                    ref={register}
                    name="link"
                    required
                    className="testimony"
                    maxLength="300"
                    style={{ width: "30%" }}
                  />
                </Form.Group>
                <Form.Group controlId="else">
                  <Form.Label>
                    <strong>
                      {t("submit.submission.other.testimony.label")}
                    </strong>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    ref={register}
                    name="text"
                    className="testimony"
                    placeholder={t(
                      "submit.submission.other.testimony.placeholder"
                    )}
                    maxLength="10000"
                    onKeyPress={(e) =>
                      e.key === "Enter" ? e.stopPropagation() : null
                    }
                  />
                </Form.Group>
              </TabContent>
            </Tab>
          )}
          {t("submit.submission.file.enabled") && (
            <Tab eventKey="file" title={t("submit.submission.file.tab")}>
              <div className="m-4">{t("submit.submission.file.text")}</div>
              <TabContent>
                <Form.Group>
                  <Form.File
                    id="exampleFormControlFile1"
                    ref={register}
                    name="file"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="else">
                  <Form.Label>
                    <strong>
                      {t("submit.submission.file.testimony.label")}
                    </strong>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    ref={register}
                    name="text"
                    placeholder={t(
                      "submit.submission.file.testimony.placeholder"
                    )}
                    maxLength="10000"
                    onKeyPress={(e) =>
                      e.key === "Enter" ? e.stopPropagation() : null
                    }
                  />
                </Form.Group>
              </TabContent>
            </Tab>
          )}
          {t("submit.submission.comment.enabled") && (
            <Tab eventKey="comment" title={t("submit.submission.comment.tab")}>
              <div className="m-4">{t("submit.submission.comment.text")}</div>
              <TabContent>
                <div>
                  <Form.Group controlId="sub-commentid">
                    <Form.Label>
                      <strong>{t("submit.submission.comment.id.label")}</strong>
                    </Form.Label>
                    <Form.Control
                      ref={register}
                      className="testimony"
                      name="submission"
                      defaultValue={prefillid}
                      placeholder={t(
                        "submit.submission.comment.id.placeholder"
                      )}
                      required
                      maxLength="10"
                      style={{ width: "20%" }}
                    />
                  </Form.Group>
                  <Form.Group controlId="sub-commenttext">
                    <Form.Label>
                      <strong>
                        {t("submit.submission.comment.testimony.label")}
                      </strong>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      ref={register}
                      name="text"
                      className="testimony"
                      placeholder={t(
                        "submit.submission.comment.testimony.placeholder"
                      )}
                      required
                      maxLength="10000"
                      onKeyPress={(e) =>
                        e.key === "Enter" ? e.stopPropagation() : null
                      }
                    />
                  </Form.Group>
                </div>
              </TabContent>
            </Tab>
          )}
        </Tabs>
      )}
    </>
  );
};

export default FormTabs;

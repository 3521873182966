import { Trans, useTranslation } from "react-i18next";
import ALink, { MLink } from "../ALink/ALink.jsx";

const Feedback = () => {
  const { t } = useTranslation();
  return (
    <div>
      {t("submit.feedback.hearings.enabled") && (
        <p>
          <Trans
            i18nKey="submit.feedback.hearings.text"
            components={{ ALINK: <ALink /> }}
          />
        </p>
      )}
      {t("submit.feedback.email.enabled") && (
        <p>
          <Trans
            i18nKey="submit.feedback.email.text"
            components={{ MLINK: <MLink /> }}
          />
        </p>
      )}
      {t("submit.feedback.mailing.enabled") && (
        <Trans i18nKey="submit.feedback.mailing.text" />
      )}
    </div>
  );
};

export default Feedback;

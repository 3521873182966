import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import { useTranslation, Trans } from "react-i18next";

const AdvancedSearch = (props) => {
  const { t } = useTranslation();

  const { numMatches, setSearchFields } = props;

  const { register, handleSubmit } = useForm();

  const [searchResults, setSearchResults] = useState(null);

  const clear = () => {
    setSearchFields({});
  };

  useEffect(() => {
    if (numMatches === 0) {
      setSearchResults(
        <Alert
          variant="danger"
          onClose={() => setSearchResults(null)}
          dismissible
        >
          {t("search.alert.nothing")}
          {t("support")}
        </Alert>
      );
    } else {
      setSearchResults(
        <Alert
          variant="success"
          onClose={() => setSearchResults(null)}
          dismissible
        >
          <Trans
            i18nKey={`search.alert.found${numMatches > 1 ? "_plural" : ""}`}
            values={{ numMatches: numMatches }}
          />
        </Alert>
      );
    }
  }, [t, numMatches]);

  const onSubmit = (data) => {
    setSearchFields(data);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} controlId="searchKeyword">
            <Form.Label>{t("search.fields.keyword.label")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("search.fields.keyword.placeholder")}
              name="keyword"
              ref={register}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="searchTag">
            <Form.Label>{t("search.fields.tag.label")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("search.fields.tag.placeholder")}
              name="tag"
              ref={register}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="searchAuthor">
            <Form.Label>{t("search.fields.author.label")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("search.fields.author.placeholder")}
              name="author"
              ref={register}
            />
          </Form.Group>
          <Form.Group as={Col} controlID="searchType">
            <Form.Label>{t("search.fields.type.label")}</Form.Label>
            <Form.Control as="select" name="type" ref={register}>
              {
                t("search.fields.type.options", {returnObjects:true}).map(option=>
                  {
                    return <option key={option.value} value={option.value}>{option.text}</option> 
                  }
                  )
              }
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlID="searchMinDate">
            <Form.Label>{t("search.fields.start.label")}</Form.Label>
            <Form.Control type="date" name="minDate" ref={register} />
          </Form.Group>
          <Form.Group as={Col} controlID="searchMaxDate">
            <Form.Label>{t("search.fields.end.label")}</Form.Label>
            <Form.Control type="date" name="maxDate" ref={register} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="searchKeyword">
            <Form.Label>{t("search.fields.link.label")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("search.fields.link.placeholder")}
              name="link"
              ref={register}
            />
          </Form.Group>
        </Form.Row>
        {searchResults ? searchResults : ""}
        <div className="text-right">
          <Button variant="primary" type="submit">
            {t("buttons.search")}
          </Button>
          <Button variant="primary" type="reset" onClick={clear}>
            {t("buttons.clear")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AdvancedSearch;

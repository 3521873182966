import React, { useState, useEffect } from "react";

import Card from "react-bootstrap/Card";

import "./GalleryCard.scss";

import iconWritten from "../../assets/chat.png";
import iconLink from "../../assets/link.png";

const GalleryCardIcon = (props) => {
  const { title, id, type, link } = props;

  const [image, setImage] = useState(iconWritten);

  const getType = (type) => {
    return type;
  };

  useEffect(() => {
    if (type === "plan" || type === "draft") {
      let did = link.split("/").slice(-1)[0];
      did = did.split("?")[0].split("&")[0];
      if (did.length) {
        setImage(`https://districtr-public.s3.us-east-1.amazonaws.com/${did}.png`);
      }
    } else if (type === "coi") {
      let did = link.split("/").slice(-1)[0];
      did = did.split("?")[0].split("&")[0];
      if (did.length) {
        setImage(`https://districtr-public.s3.us-east-1.amazonaws.com/${did}.png`);
      }
    } else if (type === "written") {
      setImage(iconWritten);
    } else if (type === "file" || type === "other") {
      setImage(iconLink);
    }
  }, [link, type]);

  return (
    <div className="preview">
      <a href={"/submission/" + type[0] + id}>
        <Card
          className="shadow p-3 mb-5 bg-white"
          border={getType(type)}
          style={{ minHeight: 250 }}
        >
          <Card.Body className={"icon"}>
            <Card.Img
              variant="top"
              alt="Map preview"
              src={image}
              height="120"
              width="144"
              onError={(e) => {e.target.onerror = null; e.target.src= iconWritten }}
            />
            <hr />
            <Card.Title className={getType(type)}>
              <strong>{title}</strong>
            </Card.Title>
          </Card.Body>
        </Card>
      </a>
    </div>
  );
};

export default GalleryCardIcon;

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { useState, useEffect } from "react";

import "./App.scss";

import Navigation from "../Navigation/Navigation.jsx";
import Welcome from "../Welcome/Welcome.jsx";
import Rules from "../Rules/Rules.jsx";
import Submit from "../Submit/Submit.jsx";
import HowToDraw from "../HowToDraw/HowToDraw.jsx";
import Gallery from "../Gallery/Gallery.jsx";
import DetailPage from "../DetailPage/DetailPage.jsx";
import SearchPage from "../Search/SearchPage.jsx";
import DraftPlanPage from "../DraftPlans/DraftPlanPage";

import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { getOrganization } from "../../organizations";
const org = getOrganization();


if (org) {
  const language = window.localStorage.getItem('language') || 'en'
  i18n.changeLanguage(`${language}-${org}`);
}

const App = () => {
  const { t } = useTranslation();

  const [refresh, triggerRefresh] = useState(false);

  const [font, setFont] = useState("");

  const switchLang = (language) => {
    i18n.changeLanguage(`${language}-${org}`);
    window.localStorage.setItem('language', language)
    setFont(language === "nv" ? "timenrn" : "");
  };

  useEffect(() => {
    if (window.location.href.includes("#"))
      window.location.href = "#" + window.location.href.split("#")[1];
  }, []);

  window.document.title = t("header");
  // Add an org-specific css file if one has been specified
  const css = i18n.t("theme.site-css");
  if (css) {
    var cssId = "sitecss";
    if (!document.getElementById(cssId)) {
      var body = document.getElementsByTagName("body")[0];
      var link = document.createElement("link");
      link.id = cssId;
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = css;
      link.media = "all";
      body.appendChild(link);
    }
  }

  const sections = {
    welcome: <Welcome org={org} />,
    rules: <Rules />,
    submit: <Submit refresh={refresh} triggerRefresh={triggerRefresh} />,
    how: <HowToDraw />,
    gallery: <Gallery refresh={refresh} />,
  };

  return (
    <>
      {t("active") ? (
        <Router>
          <div className={"app m-5 " + font}>
            <Navigation switchLang={switchLang} />
            <div>
              <Switch>
                <Route exact path="/submission/:id">
                  <DetailPage org={org} />
                </Route>
                <Route exact path="/search">
                  <SearchPage />
                </Route>
                {t("drafts.enabled") && (
                  <Route exact path="/plans">
                    <DraftPlanPage
                      refresh={refresh}
                      triggerRefresh={triggerRefresh}
                    />
                  </Route>
                )}
                <Route path="/">
                  {t("order", { returnObjects: true }).map((section, i) => (
                    <div key={i}>{sections[section]}</div>
                  ))}
                </Route>
              </Switch>
            </div>
          </div>
        </Router>
      ) : (
        <div className="text-center">
          <strong>{t("placeholder")}</strong>
        </div>
      )}
    </>
  );
};

export default App;

import GalleryCardIcon from "../GalleryItem/GalleryCardIcon";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";

const PinnedPlans = () => {
  const { t } = useTranslation();
  const enacted_ush = t("examples:enacted_ush", { returnObjects: true });
  const enacted_senate = t("examples:enacted_senate", { returnObjects: true });
  const enacted_house = t("examples:enacted_house", { returnObjects: true });

  return (
    <div className="pinned">
      <Container>
        <Col>
          <Row>
            <GalleryCardIcon
              title={enacted_ush.title}
              id={"e" + enacted_ush.id}
              type={enacted_ush.type}
              link={enacted_ush.link}
            />
            <div>&nbsp;&nbsp;&nbsp;</div>
            <GalleryCardIcon
              title={enacted_senate.title}
              id={"e" + enacted_senate.id}
              type={enacted_senate.type}
              link={enacted_senate.link}
            />
            <div>&nbsp;&nbsp;&nbsp;</div>
            <GalleryCardIcon
              title={enacted_house.title}
              id={"e" + enacted_house.id}
              type={enacted_house.type}
              link={enacted_house.link}
            />
          </Row>
        </Col>
      </Container>
    </div>
  );
};

export default PinnedPlans;

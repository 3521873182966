import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Components/App/App.jsx';
import reportWebVitals from './reportWebVitals';

import './assets/custom.scss';

import './i18n'

import './fonts/timenrn.ttf';

ReactDOM.render(
  <React.StrictMode>
  <Suspense fallback="loading page ...">
  <App />
  </Suspense>
   
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

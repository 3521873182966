import React, { useState } from "react";

import Submissions from "../Submissions/Submissions.jsx";
import AdvancedSearch from "../Search/AdvancedSearch.jsx";
import Anchor from "../Anchor/Anchor.jsx";
import { useTranslation } from "react-i18next";

const SearchPage = () => {
  const [searchFields, setSearchFields] = useState({});
  const [numMatches, setNumMatches] = useState(undefined);
  const { t } = useTranslation();
  const setTag = (t) => {
    setSearchFields((searchFields) => {
      return { ...searchFields, tag: t };
    });
  };

  return (
    <div className="details">
      <Anchor>{t("search.anchor")}</Anchor>
      <div className="m-5">
        <AdvancedSearch
          setSearchFields={setSearchFields}
          numMatches={numMatches}
        />
        <Submissions
          searchFields={searchFields}
          filterTags={[searchFields.tag || ""]}
          setFilterTags={setTag}
          numMatches={numMatches}
          setNumMatches={setNumMatches}
        />
      </div>
    </div>
  );
};

export default SearchPage;

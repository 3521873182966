import { useState } from "react";
import { isMobileOnly } from "react-device-detect";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";

import { useTranslation } from "react-i18next";
import React from "react";

const UsaStates = require("usa-states").UsaStates;
const states = new UsaStates({
  includeTerritories: true,
});

const FormPersonal = (props) => {
  const { register } = props;
  const { t } = useTranslation();
  const locations = t("locations:list", { returnObjects: true });
  const options = locations.sort();

  const [city, setCity] = useState("");
  const [active, setActive] = useState(1);
  const [completions, setCompletions] = useState([]);

  const handleClick = (c) => {
    setCity(c);
  };

  const handleBlur = () => {
    setCompletions([]);
  };

  const handleChange = (e) => {
    let val = e.target.value;
    setCity(val);
    setActive(-1);
    setCompletions(
      val.length
        ? options
            .filter((option) =>
              option.toLowerCase().startsWith(val.toLowerCase())
            )
            .slice(0, 5)
        : []
    );
  };

  const handleKeyDown = (e) => {
    let val = e.target.value;
    if (e.key === "Enter") {
      if (active > -1) {
        setCity(completions[active]);
      } else if (val) {
        setCity(val);
      }
      setCompletions([]);
    } else if (e.key === "ArrowUp") {
      setActive((active) => Math.max(active - 1, -1));
    } else if (e.key === "ArrowDown") {
      setActive((active) => Math.min(active + 1, completions.length));
    }
  };

  return (
    <div>
      <Form.Row className="align-items-end">
        {t("submit.personal.salutation.enabled") && (
          <Form.Group as={Col} xs={2} controlId="formGridPrefix">
            <Form.Label className="hide-small">
              {t("submit.personal.salutation.label")}
            </Form.Label>
            <Form.Control
              ref={register}
              name="salutation"
              placeholder={t("submit.personal.salutation.placeholder")}
              maxLength="10"
            />
          </Form.Group>
        )}
        <Form.Group as={Col} controlId="formGridFirstName">
          <Form.Label>
            {t("submit.personal.first.label")}
            {t("submit.personal.first.required") && "*"}
          </Form.Label>
          <Form.Control
            ref={register}
            name="first"
            required={t("submit.personal.first.required")}
            maxLength="30"
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridLastName">
          <Form.Label>
            {t("submit.personal.last.label")}
            {t("submit.personal.last.required") && "*"}
          </Form.Label>
          <Form.Control
            ref={register}
            name="last"
            maxLength="30"
            required={t("submit.personal.last.required")}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>
            {t("submit.personal.email.label")}
            {t("submit.personal.email.required") && "*"}
          </Form.Label>
          <Form.Control
            type="email"
            ref={register}
            name="email"
            required={t("submit.personal.email.required")}
            maxLength="127"
          />
        </Form.Group>
        {t("submit.personal.phone.enabled") && (
          <Form.Group as={Col} controlId="formGridPhone">
            <Form.Label>
              {t("submit.personal.phone.label")}
              {t("submit.personal.phone.required") && "*"}
            </Form.Label>
            <Form.Control
              type="tel"
              ref={register}
              name="phone"
              maxLength="20"
              placeholder="612.555.1212"
              required={t("submit.personal.phone.required")}
            />
          </Form.Group>
        )}
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>
            {t("submit.personal.location.label")}
            {t("submit.personal.location.required") && "*"}
            <span
              className="d-none d-lg-inline-block"
              style={{ fontWeight: "normal" }}
            >
              {!isMobileOnly && !t("submit.personal.location.required") && (
                <>
                  &nbsp; {t("submit.personal.location.optionalButEncouraged")}
                </>
              )}
            </span>
          </Form.Label>
          <Form.Control
            ref={register}
            name="city"
            value={city}
            placeholder={t("submit.personal.location.placeholder")}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            maxLength="50"
            required={t("submit.personal.location.required")}
          />
          <ListGroup>
            {completions.map((c, i) => (
              <ListGroup.Item
                className="text-muted"
                key={i}
                action
                active={completions[active] === c}
                onMouseDown={() => handleClick(c)}
              >
                {c}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Form.Group>

        <Form.Group as={Col} md={3} controlId="formGridState">
          <Form.Label>
            {t("submit.personal.state.label")}
            {t("submit.personal.state.required") && "*"}
          </Form.Label>
          <Form.Control
            as="select"
            defaultValue="Choose..."
            ref={register}
            name="state"
            required={t("submit.personal.state.required")}
          >
            <option>{t("state.name")}</option>
            {states.states.map((s, i) => (
              <option key={i}>{s.name}</option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group as={Col} xs={3} controlId="formGridZip">
          <Form.Label>
            {" "}
            {t("submit.personal.zip.label")}
            {t("submit.personal.zip.required") && "*"}
          </Form.Label>
          <Form.Control
            ref={register}
            name="zip"
            maxLength="10"
            required={t("submit.personal.state.required")}
          />
        </Form.Group>
      </Form.Row>
      {t("submit.personal.organization.enabled") && (
        <Form.Row>
          <Form.Group as={Col} controlId="formGridOrganization">
            <Form.Label>
              {t("submit.personal.organization.label")}
              {t("submit.personal.organization.required") && "*"}
            </Form.Label>
            <Form.Control
              type="text"
              ref={register}
              name="coalition"
              maxLength="127"
              required={t("submit.personal.organization.required")}
            />
          </Form.Group>
        </Form.Row>
      )}
    </div>
  );
};

export default FormPersonal;

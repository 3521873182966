import axios from 'axios';
import {getEndpoint, getOrganization} from './organizations'
import i18n from './i18n'

export const MAX_DOCUMENT_SIZE_MB = 5
const PLAN_PREVIEW_ENDPOINT = 'https://o1siz7rw0c.execute-api.us-east-2.amazonaws.com/prod/submissions/planPreview'

export const postSubmission = (data) => {
    const ENDPOINT = getEndpoint()
    const ORGANIZATION = getOrganization()
    const LANGUAGE = i18n.language.split("-")[0]
    const origin  = window.location.origin;

    // patching a type error here for now
    // submission for draft comments needs a value
    if (!data.submission)
        data.submission = "d0"

    return axios
        .post(ENDPOINT, {...data, organization: ORGANIZATION, language: LANGUAGE, origin})
        .then(res => res.data)
}

export const getSignedUrl = (fileName) => {
    const ENDPOINT = getEndpoint()
    const ORGANIZATION = getOrganization()
    return axios
        .get(`${ENDPOINT}/getSignedURL`, { params: { file: fileName, organization:  ORGANIZATION} })
        .then(res => res.data)
}

// Upload directly to S3
export const uploadSubmission = (signedUrl, blobData, mimeType) => {
   return axios(signedUrl, {
        method: 'PUT',
        headers: {
            'Content-Type': mimeType
        },
        data: blobData,
    })
}
/**
 * Given a date string that represents a local date, return a Date object that is the UTC datetime corresponding to the beginning of that day
 * in the current locale.  For example, an EST a string of '2021-01-03' should convert to a Date  of  2021-01-03T05:00:00Z
 * @param {date string in the format YYYY-MM-DD} s 
 */
function parseLocalDate(s) {
    const now = new Date()
    let d = new Date(Date.parse(s) + now.getTimezoneOffset() * 1000 * 60)
    return d
}
export const getPreviewsBySearch = (keyword = "", author = "", type = "", minDate = "", maxDate = "", link = "", draft = "", tags = [], page = 1, length = 12) => {
    const ENDPOINT = getEndpoint()
    const ORGANIZATION = getOrganization()
    if (minDate) {
        minDate = parseLocalDate(minDate).toISOString()
    }
    if (maxDate) {
        const d = parseLocalDate(maxDate)
        d.setDate(d.getDate() + 1)
        maxDate = d.toISOString()
    }
    console.log(`getPreviewsBySearch ${ORGANIZATION}`)
    var params =  new URLSearchParams({
        keyword : keyword,
        author: author,
        type: type,
        minDate: minDate,
        maxDate: maxDate,
        link: link,
        draft: draft,
        page: page,
        length: length,
        organization: ORGANIZATION
    })
    for (const tag of tags) {
        params.append("tags", tag)
    }
    return axios
        .get(`${ENDPOINT}/search`, { params:params})
        .then(res => res.data)
}


export const getDetails = (id) => {
    const ENDPOINT = getEndpoint()
    const ORGANIZATION = getOrganization()
    return axios
        .get(`${ENDPOINT}/${id}`,{params: {organization: ORGANIZATION}})
        .then(res => res.data)
}

export const getDraftComments = (draft) => {
    const ENDPOINT = getEndpoint()
    const ORGANIZATION = getOrganization()
    return axios
        .get(`${ENDPOINT}/draft/${draft}`,{params: {organization: ORGANIZATION}})
        .then(res => res.data)
}

export const movePage = (page, direction) => {
    const ENDPOINT = getEndpoint()
    const ORGANIZATION = getOrganization()
    return axios
        .get(`${ENDPOINT}/move`, 
        {params: {id:page,
            direction:direction,
            organization: ORGANIZATION}})
        .then(res => res.data)
}

export const getToken = () => {
    const ENDPOINT = getEndpoint()
    const ORGANIZATION = getOrganization()
    return axios
        .get(`${ENDPOINT}/token`,{params: {organization: ORGANIZATION}})
        .then(res => res.data)
}

export const planPreview = (id, datestamp=0) => {
    return axios
        .get(`${PLAN_PREVIEW_ENDPOINT}?id=${id}&t=${datestamp}`)
        .then(res => res.data)
}

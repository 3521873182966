import React, { useState, useEffect } from "react";

import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";

import clearText from "../../clearText";

import "./GalleryCard.scss";

import iconWritten from "../../assets/chat.png";
import iconLink from "../../assets/link.png";
import { planPreview } from "../../api.js";

import { useTranslation } from "react-i18next";

const GalleryCard = (props) => {
  const { title, first, last, city, type, id, datetime, link } = props.plan;
  const tags = props.plan.tags || [];
  const numberofcomments = props.plan.numberofcomments || 0;
  const { t } = useTranslation();

  const [image, setImage] = useState(iconWritten);
  const [districttype, setDistrictType] = useState(null);

  useEffect(() => {
    if (type === "plan" || type === "coi") {
      let did = link.split("/").slice(-1)[0];
      did = did.split("?")[0].split("&")[0];
      if (did.length) {
        planPreview(did).then((api) => {
          if (api.screenshot) {
            setImage(api.screenshot);
          }
          if (api.plan && api.plan.problem && api.plan.problem.pluralNoun) {
            setDistrictType(api.plan.problem.pluralNoun);
          }
        });
      }
    } else if (type === "written") {
      setImage(iconWritten);
    } else if (type === "other" || type === "file") {
      setImage(iconLink);
    }
  }, [link, type]);

  return (
    <div className="preview">
      <a href={"/submission/" + type[0] + id}>
        <Card className="shadow p-3 mb-5 bg-white" border={type}>
          <Card.Body>
            <Card.Img
              variant="top"
              alt={`Preview image for submission ${type[0] + id}`}
              src={image}
              height="164"
              width="200"
            />
            <hr />
            <Card.Title className={type}>
              <strong>{clearText(title)}</strong>
            </Card.Title>
            <Card.Subtitle>
              <strong>{clearText(first + " " + last)}</strong>
              {city && <> - {clearText(city)}</>}
            </Card.Subtitle>
            <Card.Text>
              <small>
                {new Date(datetime).toLocaleDateString()} <br />
                {t("gallery.type")}: {clearText(type)} | ID: {type[0] + id}
                <br />
                {type === "plan" && districttype && (
                  <>
                    {clearText(districttype.replace("Reapportioned ", ""))}
                    <br />
                  </>
                )}
                {!!tags.length && (
                  <span className="tags-expo">
                    {tags.slice(0, 3).map((tag, i) => (
                      <a
                        className="post-tag"
                        href={"/?tag=" + clearText(tag) + "#gallery"}
                        key={i}
                      >
                        #{clearText(tag)}
                      </a>
                    ))}
                    {tags.length > 3 && <span>...</span>}
                  </span>
                )}
              </small>
            </Card.Text>
          </Card.Body>
          <Card.Footer className="text-muted">
            <small>
              {t("gallery.comments")}{" "}
              <Badge variant={type}>{numberofcomments}</Badge>
            </small>
          </Card.Footer>
        </Card>
      </a>
    </div>
  );
};

export default GalleryCard;

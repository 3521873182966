import clearText from "../../clearText";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { getDraftComments } from "../../api.js";

const Comments = (props) => {
  let { comments, id, isDraft } = props;
  const { t } = useTranslation();

  const [result, setResult] = useState([]);

  useEffect(() => {
    if (isDraft) {
      getDraftComments(id).then((res) =>
        setResult(res.submissionDetails.comments)
      );
    } else {
      setResult(comments);
    }
  }, [isDraft, comments]);

  return (
    <div>
      <br />
      {result.map((c, i) => (
        <div key={i} className="comment">
          {new Date(c.datetime).toLocaleDateString()} -{" "}
          <strong>{clearText(c.first + " " + c.last)}</strong>
          {c.city ? <> ({clearText(c.city)})</> : ""}: {clearText(c.text)}{" "}
          {(c.tags || []).map((tag, i) => (
            <a
              className="post-tag"
              href={"/#gallery?tag=" + clearText(tag)}
              key={i}
            >
              #{clearText(tag)}&nbsp;&nbsp;
            </a>
          ))}
        </div>
      ))}
      <div>{result.length === 0 && t("details.comments.nothing")}</div>
    </div>
  );
};

export default Comments;

import { Trans } from "react-i18next";
/*
 * A class to allow links to be embedded in translation text
 * The link should have the format of <ALINK href='someURL'>someText</ALINK>
 * @param {*} props
 * @returns
 */
const ALink = (props) => {
  const href = props.href;
  const text = props.children.toString();
  return (
    <a
      target="_blank"
      className="link link-primary"
      href={href}
      rel="noreferrer"
    >
      {text}
    </a>
  );
};

export default ALink;

/**
 * A class to allow mailto links to be embedded in translation text
 * The link should have the format of <MLINK href='someURL'>someText</MLINK>
 * @param {*} props
 * @returns
 */
export const MLink = (props) => {
  const href = "mailto:" + props.href;
  const text = props.children.toString();
  return (
    <a
      target="_blank"
      className="link link-primary"
      href={href}
      rel="noreferrer"
    >
      {text}
    </a>
  );
};

export const JLink = ({ type }) => (
  <Trans
    i18nKey={`welcome.explanations.${type}.jump`}
    components={{
      JUMP: <a href="/#how" className="link link-primary" />,
    }}
  ></Trans>
);

export const Link = (props) => {
  const target = props.target;
  const href = props.href;
  const className = props.className || "link link-primary";
  const rel = props.rel || "noreferrer";
  return (
    <a
      target={target}
      className={className}
      href={href}
      rel={rel}
    >
     {props.children}
    </a>
  );
};
export const Table = (props) => {
  const className = props.className || "";
  return (
    <table
      className={className}
    >
     {props.children}
    </table>
  );
};
export const Th = (props) => {
  const className = props.className || "";
  return (
    <th
      className={className}
    >
     {props.children}
    </th>
  );
};
export const Thead = (props) => {
  const className = props.className || "";
  return (
    <thead
      className={className}
    >
     {props.children}
    </thead>
  );
};
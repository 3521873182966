import { useState, useEffect } from "react";

import { getPreviewsBySearch } from "../../api.js";
import { useTranslation } from "react-i18next";

import GalleryCard from "../GalleryItem/GalleryCard.jsx";
import GalleryCardIcon from "../GalleryItem/GalleryCardIcon.jsx";
import GalleryListItem from "../GalleryItem/GalleryListItem.jsx";
import GalleryPaging from "./GalleryPaging.jsx";

import "./Submissions.scss";

import CardDeck from "react-bootstrap/CardDeck";
import Table from "react-bootstrap/Table";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

const GetDisplay = (props) => {
  const { t, galleryType, previews, handleTagClick } = props;
  switch (galleryType) {
    case "icons":
      return (
        <CardDeck className="gallery icons">
          {previews.map((plan, i) => (
            <li key={i} className="listItem">
              <GalleryCardIcon
                title={plan.title}
                id={plan.id}
                type={plan.type}
                link={plan.link}
              />
            </li>
          ))}
        </CardDeck>
      );
    case "list":
      return (
        <Table hover className="list-view">
          <thead>
            <tr>
              <th>{t("gallery.title")}</th>
              <th>{t("gallery.submitter")}</th>
              <th>{t("gallery.date")}</th>
              <th>{t("gallery.id")}</th>
              <th>{t("gallery.tags")}</th>
              <th>{t("gallery.comments")}</th>
            </tr>
          </thead>
          <tbody>
            {previews.map((plan, i) => (
              <GalleryListItem
                plan={plan}
                handleTagClick={handleTagClick}
                key={i}
              />
            ))}
          </tbody>
        </Table>
      );
    case "cards":
    default:
      return (
        <CardDeck className="gallery">
          {previews.map((plan, i) => (
            <li key={i} className="listItem">
              <GalleryCard plan={plan} handleTagClick={handleTagClick} />
            </li>
          ))}
        </CardDeck>
      );
  }
};

const Submissions = (props) => {
  const previewsPerPage = { cards: 12, icons: 30, list: 12 };
  const { filterTags, setFilterTags, refresh, numMatches, setNumMatches } =
    props;
  const { keyword, author, type, minDate, maxDate, link } =
    props.searchFields || {};
  const [page, setPage] = useState(1);
  const [previews, setPreviews] = useState([]);
  const [galleryType, setGalleryType] = useState("cards");
  const { t } = useTranslation();
  const search = () => {
    getPreviewsBySearch(
      keyword,
      author,
      type,
      minDate,
      maxDate,
      link,
      "",
      filterTags,
      page,
      previewsPerPage[galleryType]
    ).then((res) => {
      setNumMatches(res.matches || 0);
      setPreviews(Object.values(res.submissionPreviews));
    });
  };

  /*
  TODO
  I'm disabling a lint error about search() not being in the dependency array
  we should revisit this at some point but it works for now
  be careful if you change it that you don't create an infinite loop
  */
  useEffect(() => {
    search();
  }, [props.searchFields, page, galleryType, filterTags, refresh]);

  const handleTagClick = (e, tag) => {
    e.preventDefault();
    e.stopPropagation();
    setPage(1);
    setFilterTags([tag]);
  };

  const toggleView = (val) => {
    setGalleryType(val);
    setPage(1);
  };

  return (
    <div className="m-5">
      <fieldset>
        <div className="text-center m-3">
          <legend>{t("gallery.view.title")}</legend>
          <ToggleButtonGroup
            type="radio"
            name="gal"
            value={galleryType}
            onChange={toggleView}
          >
            <ToggleButton value={"cards"}>
              {t("gallery.view.cards")}
            </ToggleButton>
            <ToggleButton value={"list"}>{t("gallery.view.list")}</ToggleButton>
            <ToggleButton value={"icons"}>
              {t("gallery.view.icons")}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </fieldset>
      <div className="m-5">
        {numMatches === 0 ? (
          <div className="text-center">
            {t("gallery.nothing")}
            <br />
            <br />
          </div>
        ) : (
          <>
            <GetDisplay
              t={t}
              galleryType={galleryType}
              previews={previews}
              handleTagClick={handleTagClick}
            />
          </>
        )}
        <GalleryPaging
          page={page}
          setPage={setPage}
          length={previewsPerPage[galleryType]}
          numMatches={numMatches}
        />
      </div>
    </div>
  );
};

export default Submissions;

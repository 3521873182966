import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import FormPersonal from "../FormPersonal/FormPersonal.jsx";
import FormTestimony from "../FormTestimony/FormTestimony.jsx";
import Anchor from "../Anchor/Anchor.jsx";
import { useTranslation } from "react-i18next";

import {
  postSubmission,
  getSignedUrl,
  getToken,
  uploadSubmission,
  MAX_DOCUMENT_SIZE_MB,
} from "../../api.js";

import "./FormParent.scss";

const FormParent = (props) => {
  const { t } = useTranslation();

  const { register, handleSubmit, setValue /*{ watch, errors}*/ } = useForm();
  const { triggerRefresh, formType } = props;

  const [behalf, setBehalf] = useState("no");
  const [tags, setTags] = useState([]);
  const [placeTags, setPlaceTags] = useState([]);
  const [type, setType] = useState(
    formType === "drafts"
      ? "comment"
      : t("submit.submission.written.enabled")
      ? "written"
      : "plan"
  );
  const [serverResponse, setServerResponse] = useState(null);
  const [canSubmit, setCanSubmit] = useState(true);
  const [blockSubmit, setBlockSubmit] = useState(false);
  const [districtrContent, setDistrictrContent] = useState();
  // token for GET-POST continuity
  const [token, setToken] = useState();
  // token for EMAIL/POST history
  let oldToken = null;
  if (window.localStorage && window.localStorage.getItem) {
    oldToken = window.localStorage.getItem("mem_token");
  } else if (
    document.cookie &&
    document.cookie.length &&
    document.cookie.includes("mem_token=")
  ) {
    oldToken = document.cookie
      .split("mem_token=")[1]
      .split(";")[0]
      .split(" ")[0];
  }
  const [mem_token, setMemToken] = useState(oldToken);

  window.locationMark = "none";

  const onRefresh = () => {
    Array.from(document.getElementsByClassName("testimony")).forEach(
      (input) => (input.value = "")
    );
    setServerResponse(null);
    setCanSubmit(true);
    setDistrictrContent(null);
    triggerRefresh((refresh) => !refresh);
  };

  const createAndUploadDocument = (file, uploadURL, mimeType) => {
    let reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Error reading file"));
      };
      reader.onload = async (e) => {
        if (e.target.result.length > MAX_DOCUMENT_SIZE_MB * 1048576) {
          const msg = `File exceeds ${MAX_DOCUMENT_SIZE_MB}MB`;
          reject(new DOMException(msg));
        }
        const document = e.target.result;
        try {
          await uploadDocument(document, uploadURL, mimeType);
          resolve();
        } catch (err) {
          reject(err);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  // Save a document to a presigned S3 URL
  // The document is a data URL
  const uploadDocument = async (document, uploadURL, mimeType) => {
    // Strip off the beginning, which looks something like
    // data:image/png;base64,
    let binary = atob(document.split(",")[1]);
    let array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    let blobData = new Blob([new Uint8Array(array)], { type: mimeType });
    await uploadSubmission(uploadURL, blobData, mimeType);
  };

  const onSubmit = async (data) => {
    // alert(JSON.stringify({...data, type: type, tags: tags}))

    if (t("competition")) {
      data.text1 = data.text;
      data.text = `(1): ${data.text} \n (2): ${data.text2}`;
    }
    setBlockSubmit(true);
    const files = data.file;
    let firstStep;
    if (files && files.length > 0) {
      // User requested a File Upload
      const file = files[0];
      const response = await getSignedUrl(file.name);
      const { uploadURL, mimeType } = response;
      data.link = uploadURL.split("?")[0];
      firstStep = createAndUploadDocument(file, uploadURL, mimeType);
    } else {
      firstStep = Promise.resolve();
    }
    firstStep
      //what is the result variable doing here...?
      // eslint-disable-next-line no-unused-vars
      .then((result) => postSubmission({ ...data, type: type, tags: tags }))
      .then((res) => {
        setCanSubmit(false);
        setBlockSubmit(false);
        const postStatement =
          res.newtoken && res.newtoken.length
            ? t("submit.onSubmit.verification")
            : t("submit.onSubmit.success");
        const fileStatement =
          files && files.length > 0 ? t("submit.onSubmit.upload") : "";
        setServerResponse(
          <Alert variant="success">
            {postStatement} {fileStatement}
          </Alert>
        );
        if (res.newtoken && res.newtoken.length) {
          if (window.localStorage && window.localStorage.setItem) {
            window.localStorage.setItem("mem_token", res.newtoken);
          } else if (typeof document.cookie === "string") {
            document.cookie = "mem_token=" + res.newtoken;
          } else {
            console.error("Nowhere to save token");
          }
          setMemToken(res.newtoken);
        }
      })
      .catch((err) => {
        setBlockSubmit(false);
        setServerResponse(
          <Alert
            variant="danger"
            dismissible
            onClose={() => setServerResponse(null)}
          >
            {t("submit.onSubmit.failure")} {err.message}. {t("support")}
          </Alert>
        );
      });
  };

  const onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (!token) {
      getToken().then(setToken);
    }
  }, [token]);

  const buttonText = () => {
    const labels = {
      coi: t("buttons.submit.coi"),
      plan: t("buttons.submit.plan"),
    };
    return labels[type] || t("buttons.submit.default");
  };

  return (
    <div className="m-5">
      <Form
        onKeyPress={onKeyPress}
        onSubmit={handleSubmit(onSubmit)}
        className="form p-5"
      >
        <Anchor id="form" size="1.2">
          {t("submit.submission.anchor")}
        </Anchor>
        <p className="m-3">{t("submit.submission.text")}</p>
        <Form.Control
          aria-label="Hidden web token code"
          ref={register}
          name="token"
          defaultValue={token}
          style={{ display: "none" }}
        />
        <Form.Control
          aria-label="Hidden email token code"
          ref={register}
          name="mem_token"
          defaultValue={mem_token}
          style={{ display: "none" }}
        />
        <div className="m-5">
          <FormTestimony
            register={register}
            setValue={setValue}
            behalf={behalf}
            setBehalf={setBehalf}
            tags={tags}
            setTags={setTags}
            placeTags={placeTags}
            setPlaceTags={setPlaceTags}
            type={type}
            setType={setType}
            formType={formType}
            setBlockSubmit={setBlockSubmit}
            districtrContent={districtrContent}
            setDistrictrContent={setDistrictrContent}
          />
        </div>
        <Anchor size="1.2">{t("submit.personal.anchor")}</Anchor>
        <p className="m-3">{t("submit.personal.text")}</p>
        <div className="m-5">
          <FormPersonal register={register} />
        </div>
        {t("submit.checkboxes.public.enabled") && (
          <Form.Group controlId="formCheckboxPublic">
            <Form.Check
              required={t("submit.checkboxes.public.required")}
              type="checkbox"
              label={
                t("submit.checkboxes.public.label") +
                (t("submit.checkboxes.public.required") ? "*" : "")
              }
              feedback={t("submit.checkboxes.feedback")}
            />
          </Form.Group>
        )}
        {t("submit.checkboxes.email.enabled") && (
          <Form.Group controlId="formCheckboxConfEmail">
            <Form.Check
              required={t("submit.checkboxes.email.required")}
              type="checkbox"
              label={
                t("submit.checkboxes.email.label") +
                (t("submit.checkboxes.email.required") ? "*" : "")
              }
              feedback={t("submit.checkboxes.email.feedback")}
            />
          </Form.Group>
        )}
        {t("submit.checkboxes.followup.enabled") && (
          <Form.Group controlId="formCheckboxFollowup">
            <Form.Check
              required={t("submit.checkboxes.followup.required")}
              type="checkbox"
              ref={register}
              name="contactable"
              label={
                t("submit.checkboxes.followup.label") +
                (t("submit.checkboxes.followup.required") ? "*" : "")
              }
              feedback={t("submit.checkboxes.followup.feedback")}
            />
          </Form.Group>
        )}

        {t("submit.checkboxes.registered.enabled") && (
          <Form.Group controlId="formCheckboxRegistered">
            <Form.Check
              required={t("submit.checkboxes.registered.required")}
              type="checkbox"
              label={
                t("submit.checkboxes.registered.label") +
                (t("submit.checkboxes.registered.required") ? "*" : "")
              }
              feedback={t("submit.checkboxes.registered.feedback")}
            />
          </Form.Group>
        )}
        {serverResponse ? serverResponse : ""}
        {canSubmit ? (
          <Button type="submit" className="submit" disabled={blockSubmit}>
            {buttonText()}
          </Button>
        ) : (
          <>
            <Button type="button" onClick={onRefresh}>
              {t("buttons.refresh")}
            </Button>
            <Button
              type="submit"
              className="submit"
              style={{ display: "none" }}
              disabled={blockSubmit}
            >
              {buttonText()}
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};

export default FormParent;
